import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const root = css`
  width: 100%;
  position: fixed;
  bottom: 0vh;
  background-color: white;
  padding: calc(var(--unit) * 9);
  z-index: 11;
`;

export const wrapper = css`
  width: 100%;
  max-width: 100%;

  @media ${breakpoints.large} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const privacyPolicyLink = css`
  color: hsl(var(--color-borderBlack-2));
  font-weight: bold;
`;

export const contentWrapper = css`
  margin-left: calc(calc(var(--unit) * 30));

  @media ${breakpoints.smallMax} {
    margin-left: 0;
    margin-top: calc(var(--unit) * 20);
  }
`;

export const image = css`
  position: absolute;
  width: calc(var(--unit) * 25);
  margin-right: calc(var(--unit) * 9);
  top: -10px;
`;

export const title = css`
  font-family: Neue Machina;
  font-weight: bold;
  font-size: calc(var(--unit) * 5);
  line-height: calc(var(--unit) * 7);
`;

export const description = css`
  font-family: Aeonik;
  font-weight: normal;
  font-size: 14px;
  line-height: calc(var(--unit) * 5);
  max-width: 780px;
  margin-right: calc(var(--unit) * 15);

  @media ${breakpoints.smallMax} {
    width: 100%;
  }

  @media ${breakpoints.large} {
    margin-right: 169px;
  }
`;

export const buttonWrapper = css`
  display: flex;
  justify-content: center;

  margin-top: calc(var(--unit) * 10);

  & > button {
    margin-right: calc(var(--unit) * 2);
  }

  @media ${breakpoints.smallMax} {
    display: block;
  }
`;

export const buttonPrimary = css`
  display: block;
  font-weight: bold;
  font-family: Aeonik;
  font-size: clac(var(--unit) * 4);
  line-height: clac(var(--unit) * 6);
  background-color: hsl(var(--color-rangeBlue-9));
  padding: calc(var(--unit) * 3) calc(var(--unit) * 6);
  border: none;
  border-radius: 3px;
  height: auto;
  cursor: pointer;
  transition: 0.7s;
  margin-right: calc(var(--unit) * 2);

  &:hover {
    background-color: hsl(var(--color-rangeBlue-8));
  }

  @media ${breakpoints.smallMax} {
    max-width: 100%;
    width: 100%;
    margin-top: calc(var(--unit) * 2);
    margin-bottom: calc(var(--unit) * 2);
    padding-left: o;
    padding-right: o;
    margin-right: 0;
  }

  @media ${breakpoints.mediumMax} {
    margin-bottom: calc(var(--unit) * 2);
    margin-right: 0;
  }
`;

export const buttonSecondary = css`
  display: block;
  width: 200px;
  font-family: Aeonik;
  font-weight: bold;
  font-size: calc(var(--unit) * 4);
  line-height: calc(var(--unit) * 6);
  color: hsl(var(--color-text-1));
  padding: calc(var(--unit) * 3) 0;
  border: 1px solid #c1c7cd;
  border-radius: 3px;
  height: auto;
  cursor: pointer;
  background-color: transparent;
  transition: 0.7s;

  &:hover {
    background-color: #c1c7cd;
  }

  @media ${breakpoints.smallMax} {
    margin-top: calc(var(--unit) * 2);
    margin-bottom: calc(var(--unit) * 2);
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }

  @media ${breakpoints.mediumMax} {
    margin-bottom: calc(var(--unit) * 2);
    margin-right: 0;
  }
`;
